@import url('https://fonts.googleapis.com/css?family=Permanent+Marker:regular&subset=latin,latin-ext');

html {
    scroll-padding-top: 70px;
}

#root {
    background-color: #000;
    background-image: url("../public/img/bg.png");
    background-repeat: repeat;
    background-attachment: scroll;
    color: #CCC;
}

section {
    background-color: #000C;
    margin: 2rem;
    padding: 1rem;
}

.red-head {
    color: #A00;
}

h1.red-head {
    color: #F00;
}

h1, h2 {
    font-family: 'Permanent Marker', sans-serif;
    text-align: center;
}

h1 {
    padding-top: 4.5rem;
    font-size: 500%;
    text-shadow: 0 0 0.1em #F00, 0 0 0.2em #C00;
}

h2 {
    font-size: 300%;            
}

.header {
    text-align: center;
}

button.accordion-button:not(.collapsed) {
    background-color: #111;
    color: #FFF;
}

button.accordion-button:focus {
    box-shadow: none;
}

button.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

button.accordion-button.collapsed::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

button {
    padding: 1rem;
}

.contact > a, .contact > a:visited, .contact > a:hover {
    display: block;
    color: #FFF;
    text-decoration: none;
    font-family: 'Special Elite', cursive;
    border-radius: 5px;
    font-size: 16pt;
    padding: 5px 20px;
}

.contact > a:hover {
    background-color: #333;
    text-decoration: none;
}

.contact > a > img {
    width: 64px;
    height: 64px;
    margin-right: 0.5em;
}
